import React from 'react'

const Footer = () => {
    return(
        <div className="text-center pt-3 mb-0 alert alert-danger" role="alert">
            Created and Designed by Uma
        </div>   
    )
}

export default Footer